import React , {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { getTurnos } from '../actions/turnos';
import { IoIosArrowDropleftCircle  } from "react-icons/io";
import Calendario from './calendario';
import { useNavigate  } from 'react-router-dom';



const Turnos = () => {

  const Navigate = useNavigate();

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getTurnos()); 
  }, [dispatch]); 

  
  return (
    
    <div className='' >
        <h2 className='lg:text-6xl text-4xl  font-bold text-blueBG mt-5'>
            Turnos
        </h2>
        <Calendario  />
    
        <button onClick={()=> Navigate('/menu')}>
          <IoIosArrowDropleftCircle size={50} className='mt-10 text-blueBG' />
        </button>
    </div>
  )
}

export default Turnos