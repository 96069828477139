// pequeña funcion para controlar que se halla completado el formulario de turnos
// recordar tambien evitar la inyeccion de codigo en los campos de texto
// aunque solo podria accerder un usuario logueado

import controlCharacters from './controlCharacters';

export function controlFormTurnos(data) {
    
    // if (data.aseguradoras === undefined || data.aseguradoras === null || data.aseguradoras === '') {
    //     alert('Debe seleccionar una aseguradora');
    //     return(false);
    // }
    if (data.nombre === undefined || data.nombre === null || data.nombre === '' || 
        data.nombre.length < 3 || data.nombre.length > 20 || controlCharacters(data.nombre)) 
        {
        alert('Debe ingresar un nombre válido');
        return(false);
        }
    
    if (data.apellido === undefined || data.apellido === null || data.apellido === '' 
        || data.nombre.length < 3 || data.nombre.length > 15 || controlCharacters(data.apellido)) 
        {
        alert('Debe ingresar un apellido válido');
        return(false);
        }
    
    if (data.celular === undefined || data.celular === null || data.celular === '' 
        || data.celular.length < 8 || data.celular.length > 11 || controlCharacters(data.celular))
        {
        alert('Debe ingresar un celular válido');
        return(false);
    }
    
    if (data.patente === undefined || data.patente === null || data.patente === '' 
        || data.patente.length < 6 || data.patente.length > 7 || controlCharacters(data.patente))
        {
        alert('Debe ingresar una patente válida');
        return(false);
    }
    
    else {
        return(true);
    }

}