import React , {useState, useEffect} from 'react'
import Calendar from 'react-calendar';
import { useDispatch , useSelector } from 'react-redux';
import '../App.css'
import {useNavigate} from 'react-router-dom'
import { getFeriados } from './../actions/feriados/getFeriados';
import {isSameDay} from '../helpers/isSameDay'
import { getTurnosByDay } from '../actions/turnos/getTurnosByDay';

const Calendario = ()=> {
   const [date , setDate] = useState(new Date());
   const navigate = useNavigate();
   const dispatch = useDispatch();

   useEffect(() => {
        setDate(new Date());
        }, []);

    useEffect(() => {
        dispatch(getFeriados()); 
    }, [dispatch]); 

    useEffect(() => {
        dispatch(getTurnosByDay()); 
    }, [dispatch]); 

    const feriados = useSelector(state => state.feriados.feriados);
    const turnosbyday = useSelector(state => state.turnosByDay.turnosByDay); // ruta a redux

   const onChange = date => {
      setDate(date);
      navigate('/turnosdias', {
        state: { date: date}
    }); // capto el dia y lo mando con el navigate
   };

    return (
        <div className= 'flex justify-center'>
            <div className='w-4/5 mt-10'>
                <Calendar onChange={onChange}
                    value={date} 
                    tileClassName={({ date, view }) => {
                        let classes = 'text-sm md:text-2xl';
                        if (view === 'month') {
                            if (feriados && feriados.some(d => isSameDay(new Date(d), date))) {
                                // Si es un feriado, lo pongo en gris ¿?
                                return 'bg-gray-500';
                            } 
                            if (turnosbyday) {
                                const turnoxdia = turnosbyday.find(t => isSameDay(new Date(t.fecha  + 'T12:00:00Z'), date));
                                if (turnoxdia) {
                                        if (turnoxdia.cantTurnos === 1) {
                                            classes += ' bg-green-500';
                                        } else if (turnoxdia.cantTurnos === 2) {
                                            classes += ' bg-yellow-500';
                                        } else {
                                            classes += ' bg-red-500';
                                        }
                                    }
                                }
                        }
                        return classes;
                    }} 
                />            
            </div>
        </div>
    )
}

export default Calendario