import axios from 'axios';

export function getDiasTurnos(fecha){
    return async function(dispatch){
        const json = await axios.get(`${process.env.REACT_APP_API_URL}/diaturnos`,
        { withCredentials: true,
            params: {
                fecha
            }
        });
        return dispatch({
            type: 'GET_DIAS_TURNOS',
            payload: json.data
        })
    }
};
