import React , {useState} from 'react';
import { Link } from 'react-router-dom';
import { IoEyeSharp , IoTrashSharp , IoSettingsSharp  } from "react-icons/io5";
import { useDispatch  } from 'react-redux';
import {deleteTurno} from '../actions/turnos/deleteTurno';
import { useNavigate } from 'react-router-dom';
import ModalEliminarTurno from './modalEliminarTurno';
import { getTurnoId } from './../actions/turnos/getTurnoId';
import ModalVerTurno from './modalVerTurno';


  const CardTurnos = ({ tpordia }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenVer, setIsModalOpenVer] = useState(false);
    const [turnoToDelete, setTurnoToDelete] = useState(null);
    const [turnoName, setTurnoName] = useState('');
    const [turnoData, setTurnoData] = useState('');

    
    const handleDeleteTurno = async (id) => {
        try{
            await dispatch(deleteTurno(id));
            alert ('Turno eliminado');
        }catch(error){
            console.log(error)
            alert ('Houston we have a problem' , error)
        }
        navigate('/turnos');
    }

    const handlever = async (id) => {
        try{
            const turno = await dispatch(getTurnoId(id));
            setTurnoData(turno.payload);
            setIsModalOpenVer(true);
        }catch(error){
            console.log(error)
            alert ('Houston we have a problem' , error)
        }
    }
    const handleverput = async (id) => {
      try{
          const turno = await dispatch(getTurnoId(id));
          setTurnoData(turno.payload);
          navigate(`/modificarTurno/${id}`, { state: { turnoData: turno.payload } });
          setIsModalOpenVer(true);
      }catch(error){
          console.log(error)
          alert ('Houston we have a problem' , error)
      }
  }

    const handleOpenModal = (id , name) => {
        setIsModalOpen(true);
        setTurnoName(name);
        setTurnoToDelete(id);

    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleCloseModalVer = () => {
        setIsModalOpenVer(false)
    }

    const handleConfirmDelete = () => {
        handleDeleteTurno(turnoToDelete);
        setIsModalOpen(false);
    }

    if (!Array.isArray(tpordia.turnosByDay)) {
      return <p>No hay turnos disponibles.</p>;
    }
  
    return (
        <div className="flex flex-col">
          <div className="mx-auto bg-white shadow-md rounded-md overflow-hidden my-4 w-[95vw] lg:w-[85vw] max-w-full">
            <div className="p-4">
              <h2 className="text-2xl font-semibold mb-2">Datos</h2>
              <table className="mb-4 w-full lg:text-lg">
                <thead>
                  <tr>
                    <th className="pr-2">Nombre</th>
                    <th className="pr-2 hidden md:table-cell">Patente</th>
                    <th className="pr-2">Celular</th>
                    <th className="pr-2 hidden md:table-cell">Aseguradora</th>
                    <th className="pr-2">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {tpordia.turnosByDay.map((t) => (
                    <tr key={t.id}>
                      <td className="p-1">{t.persona.nombre}</td>
                      <td className="p-1 hidden md:table-cell">{t.vehiculo.patente}</td>
                      <td className="p-1">{t.persona.celular}</td>
                      <td className="p-1 hidden md:table-cell">{t.aseguradoras}</td>
                      <td className="p-1">
                        <button className="text-blue-500 p-1" title="Ver" onClick={() => handlever(t.id)}>
                          <IoEyeSharp />
                        </button>
                        <ModalVerTurno
                          isOpen={isModalOpenVer} 
                          closeModal={handleCloseModalVer} 
                          turnoData={turnoData}
                        />
                        <button onClick={() => handleverput(t.id)}>
                          <IoSettingsSharp />
                        </button>
                        <button className="text-red-500 p-1" title="Borrar" onClick={() => handleOpenModal(t.id , t.persona.nombre)}>
                          <IoTrashSharp />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ModalEliminarTurno isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirmDelete}>
                <h2>¿Estás seguro de que quieres eliminar el turno de {turnoName}?</h2>
              </ModalEliminarTurno>
            </div>
          </div>
        </div>
      )
    }
  
  export default CardTurnos;
