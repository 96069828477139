import React from 'react'

const index = () => {
  return (
    <div>
        <h1 className='text-blueBG mt-5 text-sm sm:text-lg md:text-xl lg:text-3xl font-bold font-serif'
        >GMS - Taller Integral</h1>
        <div className ="mt-10 max-h-[900px] flex items-center justify-center">
          <img className="rounded object-contain mx-auto max-w-[350px] max-h-[350px] 
          md:max-w-[450px] md:max-h-[450px] lg:max-w-[650px] lg:max-h-[650px]"
           src="/imagenes/fondo.png" alt="Fondo"/>
        </div>
    </div>
  )
}

export default index