import axios from "axios";

export function login (payload){
    return async function(dispatch){ 
        try{
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/loggin/ingresar` , payload, {withCredentials: true})
            return {
                type: 'LOGIN',
                payload: response.data
            }
        }catch(error){
            console.log(error)
        }
    }     
}