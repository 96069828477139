import React , {useState , useEffect } from 'react';
import { useNavigate , useLocation   } from 'react-router-dom';
import { IoArrowBackCircle , IoCheckmarkCircle , IoAdd , IoRemove } from "react-icons/io5";
import { useDispatch , useSelector } from 'react-redux';
import moment from 'moment';
import { getAseguradoras } from '../actions/aseguradoras/getAseguradoras';
import { controlFormTurnos } from '../helpers/controlFormTurnos';
import { updateTurno } from '../actions/turnos/putTurno';




const ModificarTurno = () => {

    const location = useLocation();
    const date = location.state && location.state.date ? location.state.date : null;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    
   
    const [form, setForm] = useState(null);

    useEffect(() => {
        dispatch(getAseguradoras()); 
    }, [dispatch]); 

    const aseguradoras = useSelector(state => state.aseguradoras.aseguradoras);

    useEffect(() => {
        
        
        if (location.state && location.state.turnoData) {
            const turno = location.state && location.state.turnoData ? location.state.turnoData.turno : null;
          setForm({
            patente: turno.patente,
            nombre: turno.nombre,
            apellido: turno.apellido,
            celular: turno.celular,
            cantDias: turno.cantDias,
            fecha: turno.fecha,
            fechaInicio: turno.fechaInicio,
            aseguradoras: turno.aseguradora,
            observaciones: turno.observaciones,
            id: turno.id
          });
        }
        setLoading(false);
    }, [date]);

    if (loading) {
        return <div>Cargando...</div>;
      }

    const handleFormChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();     
    }
    
    const handleBackClick = () => {
        navigate('/turnos');
    }

    const handleSubmitForm = async(e) => {
        e.preventDefault();
        if (controlFormTurnos(form)){ 
            await dispatch(updateTurno( form.id , form));
            navigate('/turnos');
            alert('Turno modificado con exito');
        }
    }
//________________ CONTADOR DE DIAS _________________________
    
    const incrementDays = () => {
        setForm(prevForm => ({
            ...prevForm,
            cantDias: Number(prevForm.cantDias) + 1
        }));
    }

    const decrementDays = () => {
        setForm(prevForm => ({
            ...prevForm,
            cantDias: prevForm.cantDias > 1 ? Number(prevForm.cantDias) - 1 : 1
        }));
    }
//_______________FIN DE CONTADOR DE DIAS_____________________
    
    return (
        <div>
            <h1 className='mt-10'>
                ModificarTurno {date}
            </h1>
            <div>
            {form ? (
            <form id='addTurnoForm' onSubmit={handleSubmit} className='flex flex-col space-y-4 mt-10 md:flex md:flex-wrap md:justify-between'>
    <div className='w-full md:w-4/5 md:mx-auto'> 
        <div className='md:flex md:space-x-4'>
            <div className='w-4/5 mx-auto md:mx-0'>
                <label>Nombre</label>
                <input type="text" name="nombre" value={form.nombre} onChange={handleFormChange} className='w-full rounded border border-gray-300 bg-gray-100 shadow' />
            </div>
            <div className='w-4/5 mx-auto md:mx-0'>
                <label>Apellido</label>
                <input type="text" name="apellido" value={form.apellido} onChange={handleFormChange} className='w-full rounded border border-gray-300 bg-gray-100 shadow' />    
            </div>
        </div>
        <div className='md:flex md:space-x-4'>
            <div className='w-4/5 mx-auto md:mx-0'>
                <label>Celular</label>
                <input type="tel" name="celular" value={form.celular} onChange={handleFormChange} className='w-full rounded border border-gray-300 bg-gray-100 shadow' />
            </div>
            <div className='w-4/5 mx-auto md:mx-0'>
                <label>Patente</label>
                <input type="text" name="patente" value={form.patente} onChange={handleFormChange} className='w-full rounded border border-gray-300 bg-gray-100 shadow' />
            </div>  
            <div className='w-4/5 mx-auto md:mx-0'>
                <label>Aseguradora</label>
                <select name="aseguradoras" value={form.aseguradoras} onChange={handleFormChange} className='w-full rounded border border-gray-300 bg-gray-100 shadow'>
                    {aseguradoras && aseguradoras.map((a)  => ( 
                    <option value={a.nombre} key={a.id}>
                        {a.nombre}</option>
                    ))}  
                </select>
            </div>
        </div>
    </div>
    <div className='w-full md:w-4/5 md:mx-auto'>
        <label> Cantidad de dias</label>
        <div className='flex justify-center w-4/5 mx-auto'>
            <button onClick={incrementDays} className='rounded border border-gray-300 bg-gray-100 shadow mr-2'>
                <IoAdd size={48} color= 'Black' className='' />
            </button>
            <label className='rounded border border-gray-300 bg-gray-100 shadow flex items-center justify-center text-xl' style={{width: '50px', height: '50px'}}>{form.cantDias}</label>
            <button onClick={decrementDays} className='rounded border border-gray-300 bg-gray-100 shadow ml-2'>
                <IoRemove size={48} color='Black' className=''/>
            </button>
        </div>
    </div>    
    <div className='w-4/5 mx-auto'>
        <label>Observaciones</label>
        <textarea name="observaciones" value={form.observaciones} onChange={handleFormChange} className='w-full rounded border border-gray-300 bg-gray-100 shadow' />
    </div>
</form> ) : (
    <div className='flex justify-center items-center h-64'>
        <p>Cargando...</p>
    </div>
)}
    <div className='flex justify-between w-full mt-10' >
        <button onClick={handleBackClick}>
            <IoArrowBackCircle size={50} color="blue" className='ml-10'/>
        </button>
        {/* lo manejo fuera del form el submit.... por una cuestion de diseño */}
        <button onClick={handleSubmitForm}>  
            <IoCheckmarkCircle size={50} color="green" className=''/>
        </button>
                <div style={{ width: 50 }}className='mr-10'></div> {/* posible tercera opcion y de paso queda todo centrado */}
    </div>      
    </div>
</div>                
                   
    )
}

export default ModificarTurno