import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { controlLogin} from '../helpers/controlLogin';
import { useNavigate  } from 'react-router-dom';
import {login}  from '../actions/login/login';
import {logout}  from '../actions/login/logout';


const Ingreso = () => {
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

  const handleUsuarioChange = (e) => {
    setUsuario(e.target.value);
  };

  const handleContrasenaChange = (e) => {
    setContrasena(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      user: usuario,
      password: contrasena,
    }
    if (!controlLogin(data)) {
      navigate('/');
    }else{
      await dispatch(login(data));
      navigate('/menu');
    }
    
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    deleteCookie('jwt');
    navigate('/');
  }

  return (
    <div className='min-h-[90px] flex items-center justify-center bg-gray-50 py-8 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='mt-0 text-center text-3xl font-extrabold text-gray-900'>
            Ingrese su usuario y contraseña
          </h2>
        </div>
        <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
          <div>
            <label htmlFor='usuario' className='sr-only'>
              Usuario
            </label>
            <input
              id='usuario'
              name='usuario'
              type='text'
              autoComplete='username'
              required
              value={usuario}
              onChange={handleUsuarioChange}
              className='appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
              placeholder='Usuario'
            />
          </div>
          <div>
            <label htmlFor='contrasena' className='sr-only'>
              Contraseña
            </label>
            <input
              id='contrasena'
              name='contrasena'
              type='password'
              autoComplete='current-password'
              required
              value={contrasena}
              onChange={handleContrasenaChange}
              className='appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mt-2'
              placeholder='Contraseña'
            />
          </div>
          <div>
            <button
              type='submit'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Iniciar sesión
            </button>
          </div>
        </form>
            <button
              type='button'
              onClick={handleLogout}
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              cerrar sesión
            </button>
      </div>
    </div>
  );
};

export default Ingreso;