import axios from 'axios';

export function deleteTurno(id){
    return async function(dispatch){
        const json = await axios.delete(`${process.env.REACT_APP_API_URL}/turnos/${id}`,{ withCredentials: true });
        return dispatch({
            type: 'DELETE_TURNOS',
            payload: id
        })
    }
};
