import axios from 'axios';

export function getTurnos(){
    return async function(dispatch){
        const json = await axios.get(`${process.env.REACT_APP_API_URL}/turnos`,
        { withCredentials: true }
        );

        return dispatch({
            type: 'GET_TURNOS',
            payload: json.data
        })
    }
};
