import axios from "axios";


export function getAseguradoras() {
    return async function (dispatch) {
        const json = await axios.get(`${process.env.REACT_APP_API_URL}/aseguradoras` , { withCredentials: true });
        return dispatch({
        type: "GET_ASEGURADORAS",
        payload: json.data,
        });
  };
}