import React , {useEffect} from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IoIosAddCircle , IoIosArrowDropleftCircle  } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import CardTurnos from './cardsTurnos';
import {getDiasTurnos} from '../actions/turnos/getDiaTurno';

const DiaTurnos = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const locationDate = new Date(location.state.date); // obtengo la fecha del state
    const date = locationDate.toLocaleDateString('es-AR'); // la paso al formato que estoy usando

    
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // borro la hora de la fecha que tengo y de la fecha actual
    locationDate.setHours(0, 0, 0, 0); 
    const isPastDate = locationDate < currentDate;

    const handleBack = () => {
        navigate('/turnos');
    }

    const handleAdd = () => {
        navigate('/agregarTurno', {
            state: { date: date }
        });
    }
    
    
    // Formatea 'fechaDate' como 'yyyy-mm-dd'
    const [day, month, year] = date.split('/');

    // Reorganiza los componentes en formato 'yyyy-mm-dd'
    const fechaFormatted = `${year}/${month}/${day}`;
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDiasTurnos(fechaFormatted)); 
    }, [dispatch , fechaFormatted]);

    const turnosdelDia = useSelector(state => state.diaTurnos);

    
    return (
        <div className='flex flex-col items-center '>
            <h1 className='text-4xl font-bold text-blueBG'> Turnos : {date}</h1>
            <div>
                <CardTurnos className='mt-20' tpordia={turnosdelDia}/>
            </div>
            <div className='flex justify-between w-full mt-10' >
                    <button onClick={handleBack}>
                        <IoIosArrowDropleftCircle size={50} className='ml-10 text-blueBG' />
                    </button>
                    <button onClick={handleAdd} disabled={isPastDate}>
                        <IoIosAddCircle size={50} className='text-blueBG'/>
                    </button>
                <div style={{ width: 50 }}className='mr-10'></div> {/* posible tercera opcion y de paso queda todo centrado */}
            </div>
        </div>
    )
}

export default DiaTurnos