
const initialState={
    turnos : [],
    turno: [],
    feriados: [],
    aseguradoras: [],
    turnosByDay: [],
    diaTurnos: [],
    user: []
}

function rootReducer(state = initialState , action){
    switch(action.type){
        case 'GET_TURNOS':
            return{
                ...state,
                turnos: action.payload
            }
        case 'POST_TURNOS':
            return{
                ...state,
                //turnos: state.turnos.concat(action.payload) // solo el ...state???
            }
        case 'GET_TURNOSBYDAY':
            return{
                ...state,
                turnosByDay: action.payload
            }
        case 'GET_DIAS_TURNOS':
            return{
                ...state,
                diaTurnos: action.payload
            }
        case 'DELETE_TURNOS':
            return{
                ...state,
                turnos: Array.isArray(state.turnos) ? state.turnos.filter(turno => turno.id !== action.payload) : [],
                diaTurnos: Array.isArray(state.diaTurnos) ? state.diaTurnos.filter(turno => turno.id !== action.payload) : []

                //creo un nuevo array con los turnos menos el que borre
            }
        case 'GET_TURNOID':
            return{
                ...state,
                turno: action.payload
            }
        case 'UPDATE_TURNOS':
            return{
                ...state,
                turno: state.turnos.map(turno => turno.id === action.payload.id ? action.payload : turno)
            }
        case 'GET_FERIADOS':
            return{
                ...state,
                feriados: action.payload
            }
        case 'GET_ASEGURADORAS':
            return{
                ...state,
                aseguradoras: action.payload
            }
        case 'LOGIN':
            return{
                ...state,
                user: action.payload
            }
        case 'LOGOUT':
            return{
                ...state,
                user: action.payload
            } 
        default:
            return state;
    }
}

export default rootReducer;