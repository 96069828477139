import React from 'react'

const ModalEliminarTurno = ({ isOpen, onClose, onConfirm, children }) => {
    if (!isOpen) {
        return null;
    }
    return (
        <div className='modal fixed inset-0 flex items-center justify-center z-50'>
            <div className='modal-content bg-white p-6 rounded shadow-lg w-1/2'>
                {children}
                <div className="flex justify-end space-x-4 mt-4">
                    <button onClick={onConfirm} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">Sí, eliminar</button>
                    <button onClick={onClose} className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400">Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default ModalEliminarTurno;