
export function controlLogin(data) {
    
    if (data.user === undefined || data.user === null || data.user === '' || 
        data.user.length < 3 || data.user.length > 20) 
        {
        alert('Debe ingresar un usuario válido');
        return(false);
        }
    
    if (data.password === undefined || data.password === null || data.password === '' 
        || data.password.length < 3 || data.password.length > 15) 
        {
        alert('Debe ingresar una contraseña válida');
        return(false);
        }
    
    else {
        return(true);
    }

}