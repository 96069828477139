import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Index from './components/index';
import Turnos from './components/turnos';
import DiaTurnos from './components/diaTurnos';
import AgregarTurno from './components/agregarTurno';
import Header from './components/header';
import Footer from './components/footer';
import Menu from './components/menu';
import Ingreso from './components/ingreso';
import ProtectedRoute  from './components/protectedRoute';
import ModificarTurno from './components/modificarTurnos';

function App() {
  return (
    <BrowserRouter>
      <div className="App flex flex-col min-h-screen">
        <Header/>
        <main className='flex-1 overflow-y-auto pb-60'>
        <Routes>
        <Route path="/" element={<Index/>}/>
          <Route path="/ingreso" element={<Ingreso/>}/>
          <Route path="/menu" element={<ProtectedRoute><Menu/></ProtectedRoute>}/>
          <Route path="/turnos" element={<ProtectedRoute><Turnos/></ProtectedRoute>}/>
          <Route path="/turnosdias" element={<ProtectedRoute><DiaTurnos/></ProtectedRoute>}/>
          <Route path="/agregarTurno" element={<ProtectedRoute><AgregarTurno/></ProtectedRoute>}/>
          <Route path= '/modificarTurno/:id' element={<ProtectedRoute><ModificarTurno/></ProtectedRoute>}/>
        </Routes>
        </main>
        <Footer/> 
      </div>
    </BrowserRouter>
  );
}

export default App;