import axios from 'axios';

export function getTurnosByDay(){
    return async function(dispatch){
        const json = await axios.get(`${process.env.REACT_APP_API_URL}/byday`,{ withCredentials: true } 
        );
        return dispatch({
            type: 'GET_TURNOSBYDAY',
            payload: json.data
        });
        
    }
};