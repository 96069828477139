import axios from 'axios';

export function getFeriados(){
    return async function(dispatch){
        const json = await axios.get(`${process.env.REACT_APP_API_URL}/feriados`, { withCredentials: true });
        return dispatch({
            type: 'GET_FERIADOS',
            payload: json.data
        })
    }
};