import React from 'react';
import Modal from 'react-modal';

const ModalVerTurno = ({ isOpen, closeModal, turnoData }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    contentLabel="Turno Modal"
    style={{
      content: {
        top: '20px',
        left: '3%',
        right: '3%',
        bottom: 'auto',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    }}
  >
   <div className="flex flex-col items-center">
      {turnoData && (
        <div className="space-y-4 text-center">
          <h2 className="text-2xl font-bold">Informacion del Turno</h2>
          <p><span className="font-bold">Nombre: </span> {turnoData.turno.nombre}</p>
          <p><span className="font-bold">Apellido: </span> {turnoData.turno.apellido}</p>
          <p><span className="font-bold">Patente: </span> {turnoData.turno.patente}</p>
          <p><span className="font-bold">Fecha: </span> {turnoData.turno.fecha}</p>
          <p><span className="font-bold">Cantidad de días: </span> {turnoData.turno.cantDias}</p>
          <p><span className="font-bold">Celular:</span>
            <a 
            href={`https://wa.me/+549${turnoData.turno.celular}`} target="_blank" 
            rel="noopener noreferrer">{turnoData.turno.celular}
            </a>
          </p>
          <p><span className="font-bold">Aseguradora:</span> {turnoData.turno.aseguradora}</p>
          <p><span className="font-bold">Observaciones:</span> {turnoData.turno.observaciones}</p>
        </div>
      )}
      <div className="flex justify-center mt-4">
        <button onClick={closeModal} className="p-2 bg-blueBG text-white rounded">Cerrar</button>
      </div>
    </div>
  </Modal>
);

export default ModalVerTurno;