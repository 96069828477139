import {jwtDecode} from 'jwt-decode';
// instale jwt-decote permite decodificar tokens JWT en el lado del cliente.




function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  
  export function isAuthenticated() {
    // Obtengo el token de la cookie
    const token = getCookie(process.env.REACT_APP_COOKIE_NAME);
  
    // Si no hay token, el usuario no está autenticado
    if (!token) {
      return false;
    }
  
    //verifica la fecha de expiración del token
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // en segundos
  
    if (decodedToken.exp < currentTime) {
      // El token ha expirado, borra la cookie
      document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      return false;
    }
  
    // el usuario está autenticado y el token no ha expirado
    return true;
  }