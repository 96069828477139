import React, { useState, useEffect } from 'react';
import { AiOutlineMenu , AiOutlineClose } from 'react-icons/ai';
import { useNavigate , Link} from 'react-router-dom';

const Header = () => {
  
  const navigate = useNavigate();
  const [nav , setNav] = useState(false); // lo uso para abrir el menu
  

  return (
    <div className=' w-full bg-blueBG  shadow-lg'>
      <nav className='mx-auto flex items-center justify-between p-0 lg:px-8 mb-2 ml-10' aria-label="Global">
        <Link to='/'>
          <img className='w-28 sm:w-36 md:w-44 lg:w-60 ' src="/imagenes/gmsLogoSinFondo.png" alt="logo"/>
        </Link>
        <div className='flex-grow'></div>
        <div className='hidden md:flex items-center text-white rounded-full p-1'>
          <ul className='flex mr-5'>
            <li className='mr-3 '>
              <Link to='/'>
                Inicio
              </Link>
            </li>
            <li className='mr-3'>Imagenes</li>
            <li className='mr-3'>Quienes Somos</li>
            <li className='mr-3'>Contacto</li>
          </ul>
        </div>
        {/* Mobile Menu */}
        <div className='cursor-pointer md:hidden text-white' onClick={() => setNav(!nav)}>
          {nav ? '' : <AiOutlineMenu className='mr-10' size={20} />}
        </div>
      </nav>
        {/* Menú desplegable */}
        {nav && (
        <div className='fixed top-2 right-0 w-60 mt-4 max-h-140 bg-blue-950 bg-opacity-75 rounded-lg z-10'>
          <AiOutlineClose className='text-white ml-auto mt-3' size={20} onClick={() => setNav(!nav)} /> {/* Muestra el ícono de cierre aquí */}
          <nav className=''>
            <ul>
              <li className='cursor-pointer ml-10 text-xl py-4 flex text-white' onClick={() => {navigate('/'); setNav(!nav)}} >Inicio</li>
              <li className='cursor-pointer ml-10 text-xl py-4 flex text-white' onClick={() => {navigate('/'); setNav(!nav)}} >Imagenes</li>
              <li className='cursor-pointer ml-10 text-xl py-4 flex text-white' onClick={() => {navigate('/'); setNav(!nav)}} >Quienes Somos</li>
              <li className='cursor-pointer ml-10 text-xl py-4 flex text-white' onClick={() => {navigate('/'); setNav(!nav)}} >Contacto</li>
            </ul>
          </nav>
        </div>
    )}
  </div>
  );
};

export default Header;

