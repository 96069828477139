export default function controlCharacters(input) {
    const invalidCharacters = [
        '<', '>', '.', "'", '!', '"', '-', '?', ',' , '@' , '=',
        '(', ')', '[', ']', '{', '}', '/', '\\', '|', '#', '$', 
        '%', '^', '&', '*', '+', '~', '`', ':', ';'
        ];
    for (let i = 0; i < invalidCharacters.length; i++) {
        if (input.includes(invalidCharacters[i])) {
            return true;
        }
    }
    return false;
}