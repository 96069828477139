import axios from "axios";

export function postTurno (payload){
    return async function(dispatch){ 
        try{
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/turnos` , payload,
            { withCredentials: true })
            return {
                type: 'POST_TURNOS',
                payload: response.data
            }
        }catch(error){
            console.log(error)
        }
    }     
}