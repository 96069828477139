import React from 'react'
import {Link} from 'react-router-dom';
import { BsWhatsapp , BsInstagram , BsFacebook , BsTiktok } from "react-icons/bs";


const Footer = () => {
  return (
    <footer className='bg-blueBG text-white py-2 shadow-md w-full max-h-[240px] fixed bottom-0 p-4'>
    {/* <div className="border-t-2 border-gray-800"></div> */}
    <div className='container mx-auto grid grid-cols-12 gap-4'>
      {/* Logo */} 
      <div className='flex justify-center col-span-12 sm:col-span-6 md:col-span-6 mt0'>
      <img className='w-28 sm:w-36 md:w-44' src="/imagenes/gmsLogoSinFondo.png" alt="logoGMS"/>
      </div>

      {/* Redes Sociales - React Icons */}
      <div className='flex justify-center items-center
       col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 
       text-white mt-0 sm:text-lg md:text-xl gap-7 sm:mt-3'>
        <Link to={'https://wa.me/5492215655063'} target='_blank' rel='noopener noreferrer'>
          <BsWhatsapp className='transform transition-transform duration-300 cursor-pointer hover:scale-120'/>
        </Link>
        <Link to={'https://www.instagram.com/gmstaller?igsh=OXlsbjljb2Z2eGNl'} target='_blank' rel='noopener noreferrer'>
          <BsInstagram className='transform transition-transform duration-300 cursor-pointer hover:scale-120'/>
        </Link>
        <Link to={'https://www.facebook.com/profile.php?id=100039615266343&mibextid=ZbWKwL'} target='_blank' rel='noopener noreferrer'>
          <BsFacebook className='transform transition-transform duration-300 cursor-pointer hover:scale-120'/>
        </Link>
        <Link to={'https://www.tiktok.com/'} target='_blank' rel='noopener noreferrer' >
          <BsTiktok className='transform transition-transform duration-300 cursor-pointer hover:scale-120'/> 
        </Link>
      </div>
  </div>
    <div className='text-center mt-8 text-xs md:text-sm lg:text-base'>
  &copy; {new Date().getFullYear()} <a href='https://gcarreiras.com.ar' target='_blank' rel='noopener noreferrer'>Creado por gcarreiras</a>. Todos los derechos reservados.
    </div>
</footer>


  )
}

export default Footer