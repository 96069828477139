import axios from "axios";

export function updateTurno (id , payload){
    return async function(dispatch){ 
        try{
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/turnos/${id}` , payload,
            { withCredentials: true })
            return {
                type: 'UPDATE_TURNOS',
                payload: response.data
            }
        }catch(error){
            console.log(error)
        }
    }     
}