import axios from "axios";

export function getTurnoId(id) {
    return async function (dispatch) {
        const json = await axios.get(`${process.env.REACT_APP_API_URL}/turnos/${id}`, {
        withCredentials: true
        });
        return dispatch({
        type: "GET_TURNOID",
        payload: json.data
        });
    };
}